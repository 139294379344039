<template>
  <div class="page-rooter-fff">
    <nav-bar v-if="appTypeStr === 'mini'" :headerTitle="title" :showBack="true" :header-back="appBack"></nav-bar>
    <app-page>
      <div class="content" v-html="content"></div>
    </app-page>

  </div>
</template>

<script>
import appMixin from '@/mixin/appMixin'
import {hideAppBackBtn, appBack} from "@/lib/appMethod";
import navBar from '@/components/nav-bar/nav-bar'
import appPage from '@/common/components/appPage'

export default {
  data() {
    return {
      title: '超鹿运动小班课整期报名协议',
      content: `“28天小班课”用户协议

在同意本协议前请务必审慎阅读、充分理解各条款内容，本协议对福州超体健康科技有限公司、福州超鹿健身服务有限公司、厦门超鹿健身服务有限公司、广州超鹿健身服务有限公司下各“超鹿运动”健身馆购买“28天小班课”课程的会员有效。

一、报名方式及课程规则
1、当用户成为“超鹿运动”的会员后，方可报名购买“28天小班课”并使用。该课程目前仅支持“整期报名”，即一次性报名购买一整期12节小班课的全部课程。
2、购买“28天小班课”支持礼品卡、账户余额及现金支付。
3、“28天小班课”为固定时间课程，购买前请确认上课时间，一旦报名原则上不可退款、不可停课、不可补课，仅在下述情形下可申请办理退款：
（1）会员出现不宜运动的伤病或怀孕等情形，并提供医院证明的；
（2）因“超鹿运动”原因或不可抗力导致无法正常上课的。
   上述情形退款规则如下：
   实付金额×（1-已结束课程节数/总课程节数）- 已使用的小班课课程券金额。
   会员申请退款时，尚未使用的小班课课程券将自动失效并被收回；已使用的小班课课程券退款时将扣除相应的券面金额；小班课续课优惠券不予收回。
    签到赠送的鹿分在退款时不予收回。

二、权益规则
1、会员可享受购买折扣如下：
  89元小班课，12节原价1068元，整期报名价格为999元；
109元小班课，12节原价1308元，整期报名价格为1199元；
129元小班课，12节原价1548元，整期报名价格为1399元；
149元小班课，12节原价1788元，整期报名价格为1599元。
2、赠课福利：会员享受第3节、第6节课后分别赠送一张所报小班课金额的小班课课程券，有效期内可用于购买该档位其他任意单节小班课。小班课课程券不可延期或转赠。
3、续课优惠：会员在每期第3节课后，将获得相应小班课续课优惠券，有效期内可用于整期报名，具体如下：
999元小班课赠送满999-100元小班课续课券；
1199元小班课赠送满1199-150元小班课续课券；
1399元小班课赠送满1399-200元小班课续课券；
1599元小班课赠送满1599-250元小班课续课券。
小班课续课优惠券不可延期或转赠。

三、关于会员服务及场馆使用规则，详见《用户协议》及《入场规则》。

四、本协议的效力、解释、变更、执行及争议的解决等均适用中华人民共和国法律。因本合同产生的任何争议，双方应协商解决，协商不成的，应提交福州市鼓楼区人民法院管辖。

五、若会员对本协议有任何疑问，可以通过APP在线联系客服或致电客服，客服电话：400-700-5678。`,
    };
  },
  mixins: [appMixin],
  components: {
    navBar,
    appPage,
  },
  created() {

    try {
      hideAppBackBtn()
    } catch (e) {}

  },
  methods: {
    appBack() {
      appBack();
    },
  },
};
</script>

<style lang="less" scoped>
  .page-rooter-fff {
    padding: 32px 0;
    h2 {
      width: 100%;
      text-align: center;
      margin: 0 0 56px;
    }
    .content {
      box-sizing: border-box;
      width: 100%;
      padding: 0 32px;
      font-size: 32px;
      white-space: pre-line;
    }
  }
</style>
