<template>
  <div class="page-rooter-fff">
    <nav-bar headerTitle="整期报名加赠奖励" :showBack="true" :header-back="appBack"></nav-bar>
    <div :class="['content', 'col-start-center', appTypeStr === 'mini' ? 'mini' : '']">
      <div class="banner">
        <div class="course-item">
          <p class="p1 row-start-center">
            <img class="icon" src="https://img.chaolu.com.cn/ACT/mini-team/zzb-icon-3.png" alt="">
            <span class="f28 fw6">{{ progress.classTitle }}</span>
          </p>
          <p class="p2 f24 row-start-center">
            <span>{{ progress.showOpenTime }}</span>
            <span class="line"></span>
            <span>{{ progress.teacherName }}</span>
          </p>
        </div>
      </div>
      <div class="box">
        <template v-if='progress.rewardList!=0'>
          <p v-if="hasUserId" class="f36 fw6">
            <span class="multi-color">您的上课数</span>
            <span class="f56 red">{{ progress.overNum }}</span>
            <span class="multi-color">节</span>
          </p>
          <p v-else class="f36 fw6">
            <span class="multi-color">累计上课奖励</span>
          </p>
          <p class="p2 f22">不以是否签到来判定是否完成课程</p>
          <div class="progress">
            <div v-for="item, index in progress.rewardList" :key="index"
              :class="['progress-item', 'row-start-center', item.overFlag ? 'success' : '']">
              <div class="status-info f28 fw6">
                <p class="pp1">完成<span class="f44 red">{{ item.overNum }}</span>节</p>
                <p v-if="hasUserId" :class="['pp2', item.overFlag ? 'green' : 'grey']">{{ item.overFlag ? '已完成' : '待解锁' }}
                </p>
                <p v-else class="pp2 fw6">可获得</p>
                <img v-if="hasUserId"
                  :src="item.overFlag ? 'https://img.chaolu.com.cn/ACT/mini-team/zbb-icon-1.png' : 'https://img.chaolu.com.cn/ACT/mini-team/zzb-icon-2.png'"
                  alt="" class="status"></img>
              </div>
              <div class="voucher-list flex">
                <img v-for="img in item.voucherImageList" :key="img" :src="img" alt="" class="voucher-item">
              </div>
            </div>

          </div>
        </template>
        <div v-else class="empty">
          <img src="https://img.chaolu.com.cn/ACT/teamWarband-202308/empty.png" alt="">
            <p>当前小班课暂无优惠券奖励</p>
        </div>
      </div>
    </div>

    <van-popup v-model="tipShow" closeable position="center" @close="appBack" class="popsure">
      <div class="sure-title">提示</div>
      <div class="sure-content">请升级至最新版本查看</div>
      <div class="sure-sub" @click="upgrade">去升级</div>
    </van-popup>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { hideAppBackBtn, appBack, getAppVersion, upgrade } from "@/lib/appMethod"
import navBar from '@/components/nav-bar/nav-bar'
import { getParam, compareVersion } from '../lib/utils';

export default {
  mixins: [userMixin],
  components: {
    navBar,
  },
  data() {
    return {
      tipShow: false,
      progress: {rewardList:[]},
      hasUserId: false,
    }
  },
  async created() {
    await this.$getAllInfo()
    try {
      let version = await getAppVersion()
      console.log('当前版本', version)
      if (compareVersion(version, '2.72.10') < 0) {
        this.tipShow = true
        return
      }
      hideAppBackBtn()
    } catch (e) { }

    this.classId = getParam().classId
    this.hasUserId = getParam().userOrChildId ? true : false


    this.getProgress()
  },
  methods: {
    appBack,
    upgrade,
    getProgress() {
      this.$axios.post(`${this.baseURLApp}/activity/micro/appointComplete/showActivityProgress`, { classId: this.classId, "userId": getParam().userOrChildId }).then(res => {
        this.progress = res.data
      }).catch(() => {
        setTimeout(() => {
          appBack()
        }, 3000);
      })

    }
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  min-height: 100vh;
  background-color: #FEE8C6;
  padding: calc(100px + env(safe-area-inset-top)) 0 64px;
  box-sizing: border-box;

  &.mini {
    padding: 0 0 64px;
  }

  * {
    box-sizing: border-box;
  }

  .banner {
    width: 100%;
    height: 348px;
    padding: 0 32px;
    background-image: url(https://img.chaolu.com.cn/ACT/mini-team/zzb-banner.png);
    background-size: 100%;
    display: flex;
    align-items: flex-end;

    .course-item {
      width: 100%;
      padding: 28px;
      background: #FFFFFF;
      border-radius: 12px 12px 12px 12px;

      .icon {
        width: 40px;
        margin: 0 16px 0 0;
      }

      .p2 {
        margin: 12px 0 0;

        .line {
          width: 2px;
          height: 20px;
          background: #DDDDDD;
          margin: 0 16px;
        }
      }
    }
  }

  .box {
    width: 686px;
    padding: 28px 28px 0;
    margin: 24px 0 48px;
    background: #FFFFFF;
    border-radius: 12px;

    .multi-color {
      background: linear-gradient(90deg, #663D31 0%, #A45823 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .red {
      background: linear-gradient(180deg, #FF5D19 0%, #FF2711 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin: 0 12px;
    }

    .p2 {
      margin: 12px 0 0;
    }

    .progress {
      .progress-item {
        position: relative;
        padding: 32px 28px 32px 0;
        margin: 0 0 0 52px;

        &:not(:last-child) {
          border-bottom: 1px solid #DDDDDD;
        }

        &.success:not(:last-child) {
          &::before {
            content: '';
            width: 4px;
            height: 100%;
            background: #36C68A;
            position: absolute;
            left: -36px;
            top: 72px;
          }
        }

        .pp1 {
          margin: 0 0 16px;

          span {
            margin: 0 6px;
          }
        }

        .voucher-list {
          margin: 0 0 0 52px;
          overflow: auto;
          -webkit-overflow-scrolling: touch;

          .voucher-item {
            width: 144px;
            height: 150px;
            display: block;
            margin: 0 24px 0 0;
          }
        }

        .status-info {
           position: relative;
            width: 170px;
            flex-grow: 0;
            flex-shrink: 0;
            box-sizing: unset;
        }

        .status {
          width: 36px;
          position: absolute;
          left: -52px;
          top: 14px;
          z-index: 1;
        }
      }
    }

    .green {
      color: #36C68A;
    }

    .grey {
      color: #6C727A;
    }
  }

}

.popsure {
  width: 590px;
  border-radius: 12px;
  padding: 50px 40px;

  .sure-title {
    color: #242831;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
  }

  .sure-content {
    color: #3C454E;
    font-size: 30px;
    margin: 36px 0 48px;
    text-align: center;
  }

  .sure-sub {
    border-radius: 8px;
    background: #FFDE00;
    color: #000;
    font-weight: bold;
    font-size: 32px;
    line-height: 96px;
    text-align: center;
  }
}
.empty{
  height: 660px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #6C727A;
  font-size: 26px;
  img{
    width: 320px;
    height: 320px;
    margin-bottom: 20px;
  }
}
</style>
